/* src/QrCodeGenerator.css */

body {
    margin: 0;
    font-family: Arial, sans-serif;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    min-height: 100vh;
    background-color: #f9f9f9;
  }
  
  h1 {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }
  
  .url-input {
    padding: 10px;
    font-size: 1rem;
    width: 100%;
    max-width: 400px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .generate-button {
    padding: 10px 15px;
    font-size: 1rem;
    color: #fff;
    background-color: #28a745;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .generate-button:hover {
    background-color: #218838;
  }
  
  .qr-codes {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
  }
  
  .qr-code {
    margin: 10px;
    text-align: center;
  }
  
  .count {
    margin-top: 20px;
    font-size: 1.2rem;
  }
  